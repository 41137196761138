import React, {
  useState,
  useEffect,
} from 'react'
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Dropdown,
  ButtonGroup,
} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import {
  Client,
  formatPrice,
  BaseCard,
  DropDown,
  styles,
} from '../shared'

const Report = ({ reportData }) => {
  const tempCurrentYear = Object.keys(reportData)[0]
  const [reportView, setReportView] = useState({
    currentYear: tempCurrentYear,
    currentMonth: '01',
  })


  const client = new Client

  const parseDate = (dateString) => {
    const date = new Date(dateString)

    return date.toLocaleString(
      'default',
      { month: 'long', day: 'numeric', year: 'numeric' }
    )
  }
  const columns = [
    {
      name: 'Date',
      id: 'date',
      cell: row => parseDate(row.date)
    },
    {
      name: '# of orders',
      id: 'number_of_orders',
      cell: row => row.order_count
    },
    {
      name: 'Gross Sales',
      id: 'gross_sales',
      cell: row => formatPrice(row.item_sales)
    },
    {
      name: 'Tips',
      id: 'tips',
      sortable: true,
      cell: row => formatPrice(row.tips)
    },
    {
      name: 'Taxes',
      id: 'taxes',
      cell: row => formatPrice(row.taxes)
    },
    {
      name: 'Refunds',
      id: 'refunds',
      cell: row => formatPrice(row.refunds)
    },
    {
      name: 'Discounts',
      id: 'discounts',
      cell: row => formatPrice(row.discounts)
    }
  ]

  const {
    currentYear,
    currentMonth,
  } = reportView

  const monthsMap = {
    'January': '01',
    'February': '02',
    'March': '03',
    'April': '04',
    'May': '05',
    'June': '06',
    'July': '07',
    'August': '08',
    'September': '09',
    'October': '10',
    'November': '11',
    'December': '12',
  }
  const dailies = reportData[currentYear] &&
    reportData[currentYear]['months'][currentMonth] &&
    reportData[currentYear]['months'][currentMonth]['dailies'] ||
    []

  const yearlyGrossSales = reportData[currentYear]['item_sales']
  const yearlyDiscounts = reportData[currentYear]['discounts']
  const yearlyRefunds = reportData[currentYear]['refunds']
  const yearlyNetSales = yearlyGrossSales - yearlyDiscounts - yearlyRefunds
  const yearlyTaxes = reportData[currentYear]['taxes']
  const yearlyTips = reportData[currentYear]['tips']

  const monthlyGrossSales = reportData[currentYear]['months'][currentMonth]['item_sales']
  const monthlyDiscounts = reportData[currentYear]['months'][currentMonth]['discounts']
  const monthlyRefunds = reportData[currentYear]['months'][currentMonth]['refunds']
  const monthlyNetSales = monthlyGrossSales - monthlyDiscounts - monthlyRefunds
  const monthlyTaxes = reportData[currentYear]['months'][currentMonth]['taxes']
  const monthlyTips = reportData[currentYear]['months'][currentMonth]['tips']

  const handleSelect = (event) => {
    const selectedMonth = event.target.value;
    const parsedMonth = monthsMap[selectedMonth];
    setReportView({
      ...reportView,
      currentMonth: parsedMonth,
    })
  }

  return (
    <div
      style={{ margin: "50px" }}>
      <h1
        style={{
          fontSize: 22,
          fontWeight: "bold"
        }}>Sales Summary</h1>
      <br />
      <Row>
        <Col>
            <Form.Select
              style={{ width: "200px" }}
              value={currentYear}
              onChange={(e) => {
                const selectedYear = e.target.value;
                setReportView({
                  ...reportView,
                  currentYear: selectedYear,
                });
              }}
            >
              {Object.keys(reportData).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
          <br />
          <Row
            style={{ fontSize: 18 }}>
            <p><strong>Yearly</strong></p>
            <p>Gross Sales: {formatPrice(yearlyGrossSales)}</p>
            <p>Net Sales: {formatPrice(yearlyNetSales)}</p>
            <p>Discounts: {formatPrice(yearlyDiscounts)}</p>
            <p>Refunds: {formatPrice(yearlyRefunds)}</p>
            <p>Taxes: {formatPrice(yearlyTaxes)}</p>
            <p>Tips: {formatPrice(yearlyTips)}</p>
          </Row>
        </Col>
        <Col>
          <Form.Group controlId="formSelectMonth">
            <Form.Select
              value={Object.keys(monthsMap).find(
                (key) => monthsMap[key] === reportView.currentMonth
              ) || ""}
              onChange={handleSelect}
              style={{
                width: "200px",
              }}
            >
              <option value="" disabled>
                Month
              </option>
              {Object.keys(monthsMap).map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <br/>
          <Row
            style={{ fontSize: 18 }}>
            <p><strong>Monthly</strong></p>
            <p>Gross Sales: {formatPrice(monthlyGrossSales)}</p>
            <p>Net Sales: {formatPrice(monthlyNetSales)}</p>
            <p>Discounts: {formatPrice(monthlyDiscounts)}</p>
            <p>Refunds: {formatPrice(monthlyRefunds)}</p>
            <p>Taxes: {formatPrice(monthlyTaxes)}</p>
            <p>Tips: {formatPrice(monthlyTips)}</p>
          </Row>
        </Col>
      </Row>
      <br />
      {
        currentYear && currentMonth ? (
          <DataTable
            fixedHeader
            customStyles={styles.dataTableStyles}
            columns={columns}
            data={dailies}
            pagination
          />
        ) : null
      }
    </div>
  )
}

const NoData = () => {
  return (
    <h1>No payments found</h1>
  )
}

const ReportsIndex = ({ reportData }) => {
  return (
    <>
      {
        Object.keys(reportData).length === 0 ? (
          <NoData />
        ) : (
          <Report reportData={reportData} />
        )
      }
    </>
  )
}

export default ReportsIndex
